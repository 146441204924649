:root {
    --color-primary: #3C3F41;
    --color-secondary: #148CB1;
    --color-text-default: #3C3F41;
    --color-text-muted: #737576;

    --color-default: #3C3F41;
    --color-white: #FFF;
    --color-light-grey: #F2F2F2;

    --color-success: #1fb170;
    --color-warning: #e29a1e;
    --color-danger: #a11030;

    --color-primary-dark: #222324;
    --color-secondary-dark: #0e637d;
    --color-success-dark: #147449;
    --color-warning-dark: #b87d18;
    --color-danger-dark: #700b21;

    --color-primary-light: #767c80;
    --color-secondary-light: #19afde;
    --color-success-light: #2bc97f;
    --color-warning-light: #ffaf21;
    --color-danger-light: #da1642;

    --font-default: "Oxygen", sans-serif;
    --font-default-bold: "OxygenBold", sans-serif;
    --font-heading: "AileronHeavy", sans-serif;
    --font-heading-bold: "AileronBold", sans-serif;

    --font-size-default: 1.1rem;
    --font-default-weight: normal;
    --line-height-default: 28px;

    --navbar-height: calc(80rem/16);
    --mobile-navbar-height: calc(66rem/16);
}
